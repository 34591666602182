<template>
  <div>
    <b-card class="mt-2" >
    <template #header>
      <h6 class="mb-0">{{  $t("headingLongAost") }}</h6>
    </template>
    <b-row class="my-1">
      <b-col sm="4">
        <label for="field-aost">{{ $t("aost.labelLong") }}</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="field-aost" type="text" v-model="calcdata.aost"></b-form-input>
      </b-col>
    </b-row>
    <div>
      <b-link target="_extern" href="https://www.idm.fau.de/grow/definition/latest/FinanceAccountNew">Hier</b-link> können Sie den Workflow zur Beantragung einer neuen Anordnungsstelle starten (Link öffnet in neuem Browserfenster/neuem Tab).
    </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'TheAost',
  props: {
    calcdata: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
