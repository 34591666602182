<template>
  <b-card class="mt-2">
    <template #header>
      <h6>{{ $t('heading'+ capitalize(employmentType)+capitalize(paymentType)) }}</h6>
    </template>
    <b-table responsive class="mb-2 mt-2" small striped hover show-empty :empty-text="$t('tableEmpty.label')" :items="myEmployees" :fields="myFields">
      <template v-slot:custom-foot>
        <b-tr>
          <b-td colspan="4" class="font-weight-bold">{{ $t('sum.label') }}</b-td>
          <b-td class="text-right font-weight-bold">{{ formatEuro(totalCost) }}</b-td>
        </b-tr>
        <b-tr v-if="calcdata.overheadSurcharge">
          <b-td colspan="4" class="font-weight-bold">{{ $t('overheadSurchargeSum.label', {percent: calcdata.overheadSurcharge, pscsum: formatEuro(totalOverheadSurcharge)}) }}</b-td>
          <b-td class="text-right font-weight-bold">{{ formatEuro(totalSumOverheadSurcharge) }}</b-td>
        </b-tr>
        <b-tr v-else>
          <b-td colspan="5" class="font-weight-bold text-danger">{{ $t('overheadSurchargeSum.notcalculatable.label') }}</b-td>
        </b-tr>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import utilMixin from '@/mixins/util.mixin'
import _ from 'lodash'

export default {
  name: 'TheEmployeeList',
  mixins: [utilMixin],
  props: {
    calcdata: {
      type: Object,
      required: true
    },
    employmentType: {
      type: String,
      required: true
    },
    paymentType: {
      type: String,
      required: true
    }
  },
  computed: {
    myEmployees () {
      return _.filter(this.calcdata.employees, (emp) => {
        return emp.employmentType === this.employmentType && emp.paymentType === this.paymentType
      })
    },
    myFields () {
      if (this.employmentType === 'staff') {
        return this.staffFields.slice(0, -1)
      } else {
        return this.hkFields.slice(0, -1)
      }
    },
    totalCost () {
      return _.sum(_.map(this.myEmployees, (employee) => this.empCost(employee)))
    },
    totalOverheadSurcharge () {
      if (this.calcdata.overheadSurcharge == null) {
        return null
      }
      return (this.totalCost + Number.EPSILON) * Number(this.calcdata.overheadSurcharge) / 100
    },
    totalSumOverheadSurcharge () {
      return Number(this.totalCost + this.totalOverheadSurcharge)
    }
  }
}
</script>

<style scoped>

</style>
