<template>
  <div>
      <b-card class="mt-2">
        <template #header>
          <h6>{{ $t('machineCost.label') }}</h6>
          {{ $t('machineCost.hint') }}
        </template>
        <template>
          <b-alert :show="alertData.text != null" :variant="alertData.variant">{{ alertData.text }}</b-alert>
          <b-container fluid>
            <b-row>
              <b-col sm="4">
                <label :for="`description`">{{ $t('description.label') }}</label>
              </b-col>
              <b-col sm="8">
                <b-form-input class="mb-2" id="machineCostDescription" v-model="machineCostTemplate.description" :state="fieldState('description')"></b-form-input>
                <b-form-invalid-feedback id="field-machineCostDescription-input-live-feedback">
                  {{ $t('machineCost.description.error') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <label :for="`amount`">{{ $t('machineCost.amount.label') }}</label>
              </b-col>
              <b-col sm="8">
                <b-form-input class="mb-2" id="machineCostAmount" v-model.number="machineCostTemplate.amount" type="number" lang="de" step="0.01" min="0" :state="fieldState('amount')"/>
                <b-form-invalid-feedback id="field-machineCostAmount-input-live-feedback">
                  {{ $t('machineCost.amount.error') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
              </b-col>
              <b-col sm="8">
                <span v-if="edit">
                  <b-button size="sm" variant="success" @click="updateMachineCost(machineCostTemplate.uuid)">{{ $t('button.save.label') }}</b-button>
                  <b-button size="sm" class="ml-2" variant="danger" @click="cancelEdit">{{ $t('button.cancel.label') }}</b-button>
                </span>
                <span v-else>
                  <b-button size="sm" variant="success" @click="addMachineCost">{{ $t('button.add.label') }}</b-button>
                </span>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-card>
      <!--    Table of machineCosts-->
      <b-card v-if="calcdata.machineCosts.length" class="mt-2">
        <b-table class="mb-2 mt-2" striped small hover :items="calcdata.machineCosts" :fields="machineCostFields">
          <template #cell(actions)="data">
            <span v-if="!edit || data.item.uuid !== machineCostTemplate.uuid">
              <b-button :disabled="edit" @click="editMachineCost(data.item.uuid)" variant="info" size="sm"><b-icon-pencil/> </b-button>
              <b-button :disabled="edit" class="ml-2" @click="removeMachineCost(data.item.uuid)" variant="danger" size="sm"><b-icon-trash/></b-button>
            </span>
            <span v-else>{{$t('inEditMode.label')}}
              <b-button disabled variant="secondary" size="sm">
                <b-icon-lock />
              </b-button>
          </span>
          </template>
          <!--            Sums Footer -->
          <template v-slot:custom-foot>
            <b-tr>
              <b-td class="font-weight-bold">{{ $t('sum.label') }}</b-td>
              <b-td class="text-right font-weight-bold">{{ formatEuro(totalMachineCost()) }}</b-td>
              <b-td></b-td>
            </b-tr>
          </template>
        </b-table>
      </b-card>
  </div>
</template>

<script>
import utilMixin from '@/mixins/util.mixin'
import _ from 'lodash'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'TheMachineCost',
  mixins: [utilMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    calcdata: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      edit: false,
      machineCostTemplate: this.freshMachineCostTemplate(),
      alertData: {
        variant: 'info',
        text: null
      }
    }
  },
  validations () {
    const gtZero = (value) => value != null && Number(value) > 0
    return {
      machineCostTemplate: {
        description: { required },
        amount: { required, gtZero }
      }
    }
  },
  mounted: function () {
    window.scrollTo(0, 0)
  },
  methods: {
    fieldState (field) {
      if (this.v$.machineCostTemplate[field] == null || !this.v$.machineCostTemplate[field].$dirty) {
        return null
      }
      return !this.v$.machineCostTemplate[field].$error
    },
    addMachineCost () {
      this.v$.$touch()
      if (this.v$.machineCostTemplate.$error) return
      this.calcdata.machineCosts.push(this.machineCostTemplate)
      this.alertData.text = this.$t('entry.saved.label')
      this.resetMachineCost()
    },
    editMachineCost (uuid) {
      const machineCost = _.find(this.calcdata.machineCosts, o => o.uuid === uuid)
      this.edit = true
      this.machineCostTemplate.description = machineCost.description
      this.machineCostTemplate.amount = machineCost.amount
      this.machineCostTemplate.uuid = uuid
      return null
    },
    updateMachineCost (uuid) {
      this.v$.$touch()
      if (this.v$.machineCostTemplate.$error) return
      const machineCost = _.find(this.calcdata.machineCosts, o => o.uuid === uuid)
      this.edit = false
      machineCost.description = this.machineCostTemplate.description
      machineCost.amount = this.machineCostTemplate.amount
      this.alertData = { text: this.$t('entry.saved.label'), variant: 'info' }
      this.resetMachineCost()
    },
    removeMachineCost (uuid) {
      this.$bvModal.msgBoxConfirm(this.$t('deleteConfirm.message'), this.confirmBoxOptions())
        .then(value => {
          if (value === true) {
            const idx = _.findIndex(this.calcdata.machineCosts, o => o.uuid === uuid)
            this.calcdata.machineCosts.splice(idx, 1)
            this.alertData = { text: this.$t('entry.deleted.label'), variant: 'info' }
          } else {
            this.alertData.text = null
          }
        })
    },
    cancelEdit () {
      this.resetMachineCost()
      this.edit = false
      this.alertData.text = null
    },
    resetMachineCost () {
      this.machineCostTemplate = this.freshMachineCostTemplate()
      this.edit = false
      this.v$.$reset()
    },
    freshMachineCostTemplate () {
      return {
        description: null,
        amount: null,
        uuid: this.uuidv4()
      }
    }
  }
}
</script>

<style scoped>

</style>
