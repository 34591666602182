<template>
  <div>
    <b-card class="mt-2 infobox">
      <b-container fluid>
        <b-row>
          <b-col sm="9" class=" font-weight-bold">{{  $t('title.label') }}</b-col>
        </b-row>
        <b-row class="border-bottom">
          <b-col sm="9"><template v-if="calcdata.title">{{ calcdata.title }}</template>
            <span class="font-italic text-secondary" v-else>{{ $t('missing.text') }}</span></b-col>
        </b-row>
        <b-row>
          <b-col sm="9" class=" font-weight-bold">{{  $t('employees.label') }}</b-col>
          <b-col sm="3" class="text-right"></b-col>
        </b-row>
        <b-row>
          <b-col sm="9">{{  $t('projectfinanced.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(pfEmployeeCost()) }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="9">+{{  $t('existingfinanced.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(efEmployeeCost()) }}</b-col>
        </b-row>
        <b-row v-if="calcdata.overheadSurcharge">
          <b-col sm="9">+{{  $t('overheadSurchargePercent.label', {percent: calcdata.overheadSurcharge}) }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalOverheadSurchargeCost()) }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="9">+{{  $t('researchBonus.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(calcdata.researchBonus) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">={{ $t('employeeCost.allover.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalEmployeeResearchBonusCost()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">+{{  $t('material.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalMaterialCost()) }}</b-col>
        </b-row>
        <b-row v-if="calcdata.calculationType==='material'" class="font-weight-bold">
          <b-col sm="9">+{{  $t('machineCost.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalMachineCost()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">+{{  $t('profitSurcharge.percent.label', {percent: formatNumber(calcdata.profitSurcharge)}) }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(profitSurcharge()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">={{ $t('offerprice.short.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(offerprice()) }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="9">+{{ $t('vat.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(vat()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">={{ $t('bruttoofferprice.short.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(bruttoOfferprice()) }}</b-col>
        </b-row>
      </b-container>
    </b-card>

<!--    <b-card class="mt-2 d-sm-none d-md-block">-->
<!--      oscfac: {{ calcdata.overheadSurchargeFac }}-->
<!--    </b-card>-->
  </div>
</template>

<script>
import utilMixin from '@/mixins/util.mixin'

export default {
  name: 'TheSidebar',
  mixins: [utilMixin],

  props: {
    calcdata: {
      type: Object,
      required: false
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>
.router-link-exact-active {
  color: red;
}
.infobox {
  font-size: 0.8rem;
}
.col-sm-3, .col-sm-9, .container-fluid {
  padding-right: 1px;
  padding-left: 1px;
}
</style>
