<template>
  <div>
    <b-alert :show="hasErrors" variant="warning">
      <h5>{{ $t('calculation.hasErrors.label')}}</h5>
      <ul>
        <li v-for="item in v$.$silentErrors" :key="JSON.stringify(item)">
         {{ $t( item.$property+'.error')}}
        </li>
      </ul>
    </b-alert>
    <h3 v-if="!readOnlyView">{{ $t("headingSummary") }}</h3>
<!--    I. Allgemeine Angaben-->
    <b-card>
      <template #header>
        <h5>{{ $t("headingGeneral") }}</h5>
      </template>
      <template>
        <b-container>
          <b-row v-if="calcdata.aost" class="my-1">
            <b-col sm="3">{{ $t("aost.label") }}</b-col>
            <b-col sm="9"><span style="white-space: pre;">{{ calcdata.aost }}</span></b-col>
          </b-row>
          <b-row class="my-1" v-for="(value, name) in calcDefs" :key="name">
            <b-col sm="3">{{ $t(name+".label") }}</b-col>
            <b-col sm="9"><span style="white-space: pre;">{{ value.val }}</span></b-col>
          </b-row>
        </b-container>
      </template>
    </b-card>
<!--    II Personalkosten-->
    <b-card class="mt-3">
      <template #header>
        <h5>{{ $t("headingEmployees") }}</h5>
      </template>
      <b-card>
<!--        Projektfinanziert-->
        <template #header>
          <h6>{{  $t("headingPFEmployees") }}</h6>
        </template>
        <TheEmployeeList employmentType="staff" paymentType="project" :calcdata="calcdata"/>
        <TheEmployeeList employmentType="hk" paymentType="project" :calcdata="calcdata"/>
      </b-card>
<!--      Haushaltsfinanziert-->
      <b-card class="mt-2">
        <template #header><h6>{{  $t("headingBFEmployees") }}</h6></template>
          <TheEmployeeList employmentType="staff" paymentType="existing" :calcdata="calcdata"/>
          <TheEmployeeList employmentType="hk" paymentType="existing" :calcdata="calcdata"/>
      </b-card>
<!--      Forschungszulage -->
      <b-card class="mt-2 mb-4">
        <template #header>
          <h6>{{  $t("headingResearchBonus") }}</h6>
        </template>
        <b-container>
          <b-row class="my-1">
            <b-col sm="8" class="text-left">{{ $t("researchBonus.label") }}</b-col>
            <b-col sm="4" class="text-right">{{ formatEuro(calcdata.researchBonus)}}</b-col>
          </b-row>
        </b-container>
      </b-card>
    </b-card>
<!--    III. Sachkosten-->
    <b-card class="mt-3">
      <template #header>
        <h5>{{ $t("headingMaterials") }}</h5>
      </template>
      <h6 v-if="hasMachineCosts">{{ $t("material.label") }}</h6>
      <b-table responsive class="mb-2 mt-2" striped small hover show-empty :empty-text="$t('tableEmpty.label')" :items="calcdata.materials" :fields="materialFields.slice(0, -1)">
        <template v-slot:custom-foot>
          <b-tr>
            <b-td class="font-weight-bold">{{ $t('sum.label') }}</b-td>
            <b-td class="text-right font-weight-bold">{{ formatEuro(totalMaterialCost()) }}</b-td>
          </b-tr>
        </template>
      </b-table>
      <div v-if="hasMachineCosts" class="mt-4">
        <h6>{{ $t("machineCost.label") }}</h6>
        <b-table responsive class="mb-2 mt-2" striped small hover show-empty :empty-text="$t('tableEmpty.label')" :items="calcdata.machineCosts" :fields="materialFields.slice(0, -1)">
          <template v-slot:custom-foot>
            <b-tr>
              <b-td class="font-weight-bold">{{ $t('sum.label') }}</b-td>
              <b-td class="text-right font-weight-bold">{{ formatEuro(totalMachineCost()) }}</b-td>
            </b-tr>
          </template>
        </b-table>
      </div>
    </b-card>
    <!--    IV. Gewinnzuschlag-->
    <b-card class="mt-3 mb-5">
      <template #header>
        <h5>{{ $t("headingProfitSurcharge") }}</h5>
      </template>
      <b-container>
        <b-row class="my-1">
          <b-col sm="10" class="text-left">{{  $t('profitSurcharge.percent.label', {percent: formatNumber(calcdata.profitSurcharge)}) }}</b-col>
          <b-col sm="2" class="text-right">{{ formatEuro(profitSurcharge()) }}</b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- V. Summen -->
    <b-card>
    <template #header>
      <h5>{{ $t("headingSums") }}</h5>
    </template>
    <template>
      <b-container fluid>
        <b-row>
          <b-col sm="9" class=" font-weight-bold">{{  $t('employees.label') }}</b-col>
          <b-col sm="3" class="text-right"></b-col>
        </b-row>
        <b-row>
          <b-col sm="9">{{  $t('projectfinanced.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(pfEmployeeCost()) }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="9">+{{  $t('existingfinanced.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(efEmployeeCost()) }}</b-col>
        </b-row>
        <b-row v-if="calcdata.overheadSurcharge">
          <b-col sm="9">+{{  $t('overheadSurchargePercent.label', {percent: calcdata.overheadSurcharge}) }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalOverheadSurchargeCost()) }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="9">+{{  $t('researchBonus.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(calcdata.researchBonus) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">={{ $t('employeeCost.allover.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalEmployeeResearchBonusCost()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">+{{  $t('material.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalMaterialCost()) }}</b-col>
        </b-row>
        <b-row v-if="calcdata.calculationType==='material'" class="font-weight-bold">
          <b-col sm="9">+{{  $t('machineCost.label') }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(totalMachineCost()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">+{{  $t('profitSurcharge.percent.label', {percent: formatNumber(calcdata.profitSurcharge)}) }}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(profitSurcharge()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">={{ $t('offerprice.short.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(offerprice()) }}</b-col>
        </b-row>
        <b-row>
          <b-col sm="9">+{{ $t('vat.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(vat()) }}</b-col>
        </b-row>
        <b-row class="font-weight-bold">
          <b-col sm="9">={{ $t('bruttoofferprice.short.label')}}</b-col>
          <b-col sm="3" class="text-right">{{ formatFullEuro(bruttoOfferprice()) }}</b-col>
        </b-row>
      </b-container>
    </template>
    </b-card>
    <br />
  </div>
</template>

<script>
import TheEmployeeList from '@/components/show/TheEmployeeList'
import utilMixin from '@/mixins/util.mixin'
import _ from 'lodash'
// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { dateMixin } from '@/mixins/date.mixin'

export default {
  name: 'TheShow',
  mixins: [utilMixin, dateMixin],
  components: { TheEmployeeList },
  props: {
    calcdata: {
      type: Object,
      required: true
    },
    readOnlyView: {
      type: Boolean,
      default: true,
      required: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted: function () {
    window.scrollTo(0, 0)
  },
  validations () {
    const startIsBefore = (value, vm) => new Date(value) > new Date(this.calcdata.startDate)
    const endIsAfter = (value, vm) => new Date(value) < new Date(this.calcdata.endDate)
    // paymentType="existing"
    const enoughExisting = (value) => this.efEmployeeCost() >= Math.round(this.pfEmployeeCost() * 0.025)// _.find(value, e => e.paymentType === 'existing')
    const calcTypeCheck = (value) => !this.ownCalcPossible || (this.ownCalcPossible && value != null)
    const gteTwo = (value, vm) => value >= 2
    return {
      calcdata: {
        organization: { required },
        researchType: { required },
        calculationType: { calcTypeCheck },
        unitLeader: { required },
        projectLeader: { required },
        overheadSurcharge: { required },
        title: { required },
        shortTitle: { },
        client: { required },
        startDate: { required, endIsAfter },
        endDate: { required, startIsBefore },
        employees: { enoughExisting },
        profitSurcharge: { required, gteTwo }
      }
    }
  },
  computed: {
    definitions () {
      return this.$store.state.calc.definitions
    },
    hasErrors () {
      return this.v$.$silentErrors.length > 0
    },
    hasMachineCosts () {
      return this.calcdata.calculationType === 'material'
    },
    ownCalcPossible () {
      return this.calcdata.organization != null && _.includes(this.definitions.owncalcous, this.calcdata.organization.id)
    },
    calcDefs () {
      const defs = {
        calculationNumber: { type: 'text', val: this.calcdata.calculationNumber ? this.calcdata.calculationNumber : null },
        organization: { type: 'text', val: this.calcdata.organization ? this.calcdata.organization.name : null },
        calculationType: { type: 'text', val: this.calcdata.calculationType ? this.$i18n.t('calculationType.' + this.calcdata.calculationType + '.label') : null },
        researchType: { type: 'text', val: this.calcdata.researchType ? this.$i18n.t('researchType.' + this.calcdata.researchType + '.label') : null },
        unitLeader: { type: 'text', val: this.calcdata.unitLeader },
        projectLeader: { type: 'text', val: this.calcdata.projectLeader },
        overheadSurchargeShort: { type: 'text', val: this.calcdata.overheadSurcharge ? this.calcdata.overheadSurcharge + '%' : null },
        title: { type: 'text', val: this.calcdata.title },
        shortTitle: { type: 'text', val: this.calcdata.shortTitle },
        client: { type: 'text', val: this.calcdata.client },
        clientCountry: { type: 'text', val: this.calcdata.clientCountryName },
        startDate: { type: 'text', val: this.formatDateDay(this.calcdata.startDate) },
        endDate: { type: 'text', val: this.formatDateDay(this.calcdata.endDate) },
        comment: { type: 'textarea', val: this.calcdata.comment }
      }
      if (!this.ownCalcPossible) {
        delete defs.calculationType
      }
      return defs
    }
  },
  methods: {
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
/*#pdfContent {*/
/*  width:11in;*/
/*  height: 8.5 in;*/
/*}*/
</style>
