<template>
  <div class="container-fluid">
    <!-- Post Calc -->
    <!--    <ThePostCalculation v-if="current && current.post" :calcdata="current" @submit="saveCalc" @back="closeCalculation" @delete="deleteCalc"/>-->
    <!-- Calc Create/Edit-->
    <TheCalculation v-if="current" :calcdata="current" :ro="readonly" @submit="updateAndSaveCalc"
                    @reset="resetCalculation"
                    @back="closeCalculation" @delete="showCalcDeleteConfirm" @createNumber="createCalculationNumber"/>
    <div v-else>
<!--      Oooops, sth. wentg wrong ...-->
    </div>
  </div>
</template>

<script>
import TheCalculation from '@/components/TheCalculation'
import UtilService from '@/services/util.service'
import CalcService from '@/services/calc.service'
import _ from 'lodash'
import utilMixin from '@/mixins/util.mixin'
import { authMixin } from '@/mixins/auth.mixin'

export default {
  name: 'Calculation',
  mixins: [authMixin, utilMixin],
  components: { TheCalculation },
  props: {
  //   id: {
  //     type: String,
  //     require: false
  //   },
  //   ro: {
  //     type: Boolean,
  //     require: false,
  //     default: false
  //   }
  },
  beforeRouteEnter (to, from, next) {
    console.log('beforeRouteEnter  from: ' + from.name + ' params: ' + JSON.stringify(from.params) + ' to: ' + to.name + ' params: ' + JSON.stringify(to.params))
    next(vm => {
      if (from.name === 'adminlist') {
        vm.readonly = true
      } else {
        vm.readonly = false
      }
    })
    next()
  },
  /*  beforeRouteEnter (to, from, next) {
    console.log('beforeRouteEnter  from: ' + from.name + ' params: ' + JSON.stringify(from.params) + ' to: ' + to.name + ' params: ' + JSON.stringify(to.params))
    next()
  },
  beforeRouteLeave (to, from, next) {
    console.log('beforeRouteLeave from: ' + from.name + ' params: ' + JSON.stringify(from.params) + ' to: ' + to.name + ' params: ' + JSON.stringify(to.params))
    next()
  },
  beforeRouteUpdate (to, from, next) {
    console.log('beforeRouteUpdate from: ' + from.name + ' params: ' + JSON.stringify(from.params) + ' to: ' + to.name + ' params: ' + JSON.stringify(to.params))
    next()
  },
*/
  computed: {
    definitions () {
      return this.$store.state.calc.definitions
    }
  },
  data () {
    return {
      calcId: this.id,
      current: null,
      currentAcls: null,
      readonly: false,
      calcdataTemplate: {
        sums: { },
        employees: [],
        materials: [],
        machineCosts: [],
        calculationType: null,
        calculationNumber: null,
        researchType: 'contractresearch',
        unitLeader: null,
        projectLeader: null,
        organization: null,
        overheadSurcharge: null,
        overheadSurchargeFac: null,
        title: null,
        shortTitle: null,
        client: null,
        clientCountry: 'DE',
        clientCountryName: 'Deutschland',
        clientCountryEU: true,
        comment: null,
        researchBonus: 0,
        profitSurcharge: 2,
        // from here auto create
        startDate: null,
        endDate: null,
        aost: null,
        uuid: null
      }
    }
  },
  created () {
    console.log('Calculation -> created: ' + JSON.stringify(this.$route.params) + ' ro prop: ' + this.ro)
    if (this.$route.params.cloneof) {
      this.cloneCalculation(this.$route.params.cloneof)
      return false
    } else if (this.$route.params.id) {
      this.loadCalculation(this.$route.params.id)
    } else {
      this.newCalculation()
    }
    return false
  },
  mounted () {
    console.log('mounted')
  },
  methods: {
    showCalcDeleteConfirm () {
      this.$bvModal.msgBoxConfirm(this.$t('calculation.delete.confirm.text'), this.confirmBoxOptions())
        .then(value => {
          if (value) {
            this.deleteCalc()
          }
        })
    },
    resetCalculation (data) {
      console.log('Calculation: resetting calc')
      this.current = data
    },
    newCalculation () {
      const now = new Date()
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).toISOString().slice(0, 10)
      const oneYear = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()).toISOString().slice(0, 10)

      const newCalc = _.cloneDeep(this.calcdataTemplate)
      newCalc.startDate = tomorrow
      newCalc.endDate = oneYear
      this.current = this.prepareLocalCalculation(newCalc)
    },
    cloneCalculation (uuid) {
      console.log('cloning calc with uuid ' + JSON.stringify(uuid))
      CalcService.get(uuid).then(
        response => {
          // console.log('RESPONSE: ' + JSON.stringify(response))
          const res = _.cloneDeep(response)
          delete res.definitions
          const clone = this.prepareLocalCalculation(res)
          clone.cloneOf = uuid
          clone.isCloned = true
          clone.uuid = UtilService.uuidv4()
          clone.calculationNumber = null
          clone._meta = null
          clone.acls = null
          clone.employees.forEach(function (emp) {
            emp.uuid = UtilService.uuidv4()
          })
          clone.materials.forEach(function (mat) {
            mat.uuid = UtilService.uuidv4()
          })
          clone.machineCosts.forEach(function (mach) {
            mach.uuid = UtilService.uuidv4()
          })
          // clone.definitions = null
          // this.current = this.prepareLocalCalculation(clone)
          this.current = clone
          this.makeToast(this.$i18n.t('calcCloned.text', { id: clone.title }), this.$i18n.t('result.success.title'), 'success')
        }
      ).catch(
        error => {
          this.makeToast(this.$i18n.t('error.text', { status: error.status, message: error.message, id: 'list' }), this.$i18n.t('result.error.title'), 'danger')
        }
      )
    },
    loadCalculation (uuid) {
      console.log('loadCalculation: ' + uuid + ' ro: ' + this.ro)
      if (!this.ro) { // has already been pushed from admintable
        this.$router.push({
          name: 'calculation',
          params: { id: uuid }
        }).catch(err => err)
      }
      CalcService.get(uuid).then(
        response => {
          this.current = this.prepareLocalCalculation(response)
        }
      ).catch(
        error => {
          this.makeToast(this.$i18n.t('error.text', {
            status: error.status,
            message: error.message,
            id: 'list'
          }), this.$i18n.t('result.error.title'), 'danger')
        }
      )
    },
    prepareLocalCalculation (item) {
      const cloned = _.cloneDeep(item)
      // EmpcostService.updateSalaries(cloned.startDate, cloned.endDate)
      if (cloned.definitions) {
        console.log('loaded calculation had saved definitions, will use these')
        this.$store.commit('calc/definitions', _.cloneDeep(cloned.definitions))
      } else {
        CalcService.definitions().then(
          response => {
            this.$store.commit('calc/definitions', response, { root: true })
            console.log('loaded definitions and committed in store')
          })
      }
      UtilService.updateValuesForNewDate(cloned.startDate, cloned.endDate)
      // cloned.hkPrf = EmpcostService.hkPrf
      // cloned.staffPrf = EmpcostService.staffPrf
      return cloned
    },
    showCalculationAcls (item) {
      this.currentAcls = this.prepareLocalCalculation(item)
      this.$router.push({ name: 'calculation', params: { id: item.uuid } })
    },
    closeCalculationAcls () {
      this.currentAcls = null
      this.calcId = null
      this.$router.push({ name: 'calculation' }).catch(err => err)
    },
    submitCalculationAcls (item) {
      // console.log(JSON.stringify(item))
      const msgId = item.uuid
      if (item.uuid) {
        CalcService.updateAcls(item).then(
          response => {
            this.makeToast(this.$i18n.t('updated.text', {
              id: 'Kalkulation',
              code: response.code
            }), this.$i18n.t('result.success.title'), 'success')
            // this.closeCalculationAcls()
            this.currentAcls.acls = item.acls
          }
        ).catch(
          error => this.makeToast(this.$i18n.t('error.text', {
            id: msgId,
            status: error.status,
            message: error.message
          }), this.$i18n.t('result.error.title'), 'danger')
        )
      }
    },
    showPostCalculation (item) {
      this.current = this.prepareLocalCalculation(item)
      this.current.post = true
      this.$router.push({ name: 'calculation', params: { id: item.uuid } })
    },
    closeCalculation () {
      console.log('closeCalculation')
      this.current = null
      this.calcId = null
      this.$router.push({ name: 'calculation' }).catch(err => err)
    },
    deleteCalc () {
      CalcService.delete(this.current.uuid).then(
        () => {
          this.makeToast(this.$i18n.t('deleted.text', { id: this.current.uuid }), this.$i18n.t('result.success.title'), 'success')
          this.closeCalculation()
        },
        error => this.makeToast(this.$i18n.t('error.text', {
          status: 'na',
          message: error.message,
          id: this.current.uuid
        }), this.$i18n.t('result.error.title'), 'danger')
      )
    },
    prepareSave (calc) {
      this.calcdata = _.cloneDeep(this.current) // ist nötig, weil this.calcdata.employees etc. in utilservice verwendet wird
      calc.employees.forEach(employee => {
        const cost = this.empCost(employee)
        employee.cost = cost
        employee.OSCcost = this.addOverheadSurcharge(cost)
      })
      calc.sums = {
        // Not rounded:
        pfEmployeeCostExact: this.pfEmployeeCost(false),
        efEmployeeCostExact: this.efEmployeeCost(false),
        totalEmployeeCostExact: this.totalEmployeeCost(false),
        totalOverheadSurchargeCostExact: this.totalOverheadSurchargeCost(false),
        totalEmployeeResearchBonusCostExact: this.totalEmployeeResearchBonusCost(false),
        totalMaterialCostExact: this.totalMaterialCost(false),
        totalMachineCostExact: this.totalMachineCost(false),
        fullCostExact: this.fullCost(false),
        profitSurchargeExact: this.profitSurcharge(false),
        offerpriceExact: this.offerprice(false),
        vatExact: this.vat(false),
        bruttoOfferpriceExact: this.bruttoOfferprice(false),
        // rounded:
        pfEmployeeCost: this.pfEmployeeCost(),
        efEmployeeCost: this.efEmployeeCost(),
        totalEmployeeCost: this.totalEmployeeCost(),
        totalOverheadSurchargeCost: this.totalOverheadSurchargeCost(),
        totalEmployeeResearchBonusCost: this.totalEmployeeResearchBonusCost(),
        totalMaterialCost: this.totalMaterialCost(),
        totalMachineCost: this.totalMachineCost(),
        fullCost: this.fullCost(),
        profitSurcharge: this.profitSurcharge(),
        offerprice: this.offerprice(),
        vat: this.vat(),
        bruttoOfferprice: this.bruttoOfferprice()
      }
      calc.definitions = _.cloneDeep(this.$store.state.calc.definitions)
      this.calcdata = null
    },
    updateAndSaveCalc (calc) {
      this.current = calc
      this.saveCalc()
    },
    saveCalc (createCalcNumber = false) {
      this.prepareSave(this.current)
      if (this.current._meta) {
        if (createCalcNumber) { // save AND create calculationNumber if true
          this.current.createCalcNumber = createCalcNumber
        }
        CalcService.update(this.current).then(
          response => {
            this.current = response
            if (createCalcNumber) {
              this.makeToast(this.$i18n.t('calcNumberCreated.text', { id: this.current.calculationNumber }), this.$i18n.t('result.success.title'), 'success')
            } else {
              this.makeToast(this.$i18n.t('updated.text', { id: this.current.title }), this.$i18n.t('result.success.title'), 'success')
            }
          },
          error => this.makeToast(this.$i18n.t('error.text', {
            status: 'na',
            message: error.message,
            id: this.current.uuid
          }), this.$i18n.t('result.error.title'), 'danger')
        )
        return false
      } else { // initial save
        this.current.uuid = UtilService.uuidv4()
        CalcService.create(this.current).then(
          response => {
            this.current = response
            this.makeToast(this.$i18n.t('created.text', { id: this.current.title }), this.$i18n.t('result.success.title'), 'success')
          },
          error => this.makeToast(this.$i18n.t('error.text', {
            status: 'na',
            message: error.message,
            id: this.current.uuid
          }), this.$i18n.t('result.error.title'), 'danger')
        )
        return false
      }
    },
    createCalculationNumber () {
      this.$bvModal.msgBoxConfirm(this.$t('calculation.createCalculationNumber.confirm.text'), this.confirmBoxOptions())
        .then(value => {
          if (value) {
            this.saveCalc(true)
          }
        })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  },
  watch: {
    $route (to, from) {
      // if (to.params.id != null) {
      //   this.loadCalculation(to.params.id)
      // } else {
      //   this.current = null
      // }
      // console.log('to: ', to.params.id)
      // console.log('from: ', from)
    }
  }
}
</script>

<style scoped>

</style>
