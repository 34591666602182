<template>
  <div>
    <h2>{{ $t('headingMaterials') }}</h2>
    <b-card>
      <template #header>
        <h6>{{ $t('material.label') }}</h6>
        {{ $t('material.hint') }}
      </template>
      <b-card>
        <template>
          <b-alert :show="alertData.text != null" :variant="alertData.variant">{{ alertData.text }}</b-alert>
          <b-container fluid>
            <b-row>
              <b-col sm="4">
                <label :for="`description`">{{ $t('description.label') }}</label>
              </b-col>
              <b-col sm="8">
                <b-form-input class="mb-2" id="description" v-model="materialTemplate.description" :state="fieldState('description')"></b-form-input>
                <b-form-invalid-feedback :id="`field-description-input-live-feedback`">
                  {{ $t('material.description.error') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
                <label :for="`amount`">{{ $t('material.amount.label') }}</label>
              </b-col>
              <b-col sm="8">
                <b-form-input class="mb-2" id="amount" v-model.number="materialTemplate.amount" type="number" lang="de" step="0.01" min="0" :state="fieldState('amount')"/>
                <b-form-invalid-feedback id="`field-description-input-live-feedback`">
                  {{ $t('material.amount.error') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4">
              </b-col>
              <b-col sm="8">
                <span v-if="edit">
                  <b-button size="sm" variant="success" @click="updateMaterial(materialTemplate.uuid)">{{ $t('button.save.label') }}</b-button>
                  <b-button size="sm" class="ml-2" variant="danger" @click="cancelEdit">{{ $t('button.cancel.label') }}</b-button>
                </span>
                <span v-else>
                  <b-button size="sm" variant="success" @click="addMaterial">{{ $t('button.add.label') }}</b-button>
                </span>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-card>
      <!--    Table of Materials-->
      <b-card v-if="calcdata.materials.length" class="mt-2">
        <b-table class="mb-2 mt-2" striped small hover :items="calcdata.materials" :fields="materialFields">
          <template #cell(actions)="data">
            <span v-if="!edit || data.item.uuid !== materialTemplate.uuid">
              <b-button :disabled="edit" @click="editMaterial(data.item.uuid)" variant="info" size="sm"><b-icon-pencil/> </b-button>
              <b-button :disabled="edit" class="ml-2" @click="removeMaterial(data.item.uuid)" variant="danger" size="sm"><b-icon-trash/></b-button>
            </span>
            <span v-else>{{$t('inEditMode.label')}}
              <b-button disabled variant="secondary" size="sm">
                <b-icon-lock />
              </b-button>
          </span>
          </template>
          <!--            Sums Footer -->
          <template v-slot:custom-foot>
            <b-tr>
              <b-td class="font-weight-bold">{{ $t('sum.label') }}</b-td>
              <b-td class="text-right font-weight-bold">{{ formatEuro(totalMaterialCost()) }}</b-td>
              <b-td></b-td>
            </b-tr>
          </template>
        </b-table>
      </b-card>
    </b-card>
    <TheMachineCost v-if="calcdata.calculationType==='material'"  :calcdata="calcdata"/>
  </div>
</template>

<script>
import utilMixin from '@/mixins/util.mixin'
import _ from 'lodash'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TheMachineCost from '@/components/TheMachineCost'

export default {
  name: 'TheMaterial',
  components: { TheMachineCost },
  mixins: [utilMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    calcdata: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      edit: false,
      materialTemplate: this.freshMaterialTemplate(),
      alertData: {
        variant: 'info',
        text: null
      }
    }
  },
  validations () {
    const gtZero = (value) => value != null && Number(value) > 0
    return {
      materialTemplate: {
        description: { required },
        amount: { required, gtZero }
      }
    }
  },
  mounted: function () {
    window.scrollTo(0, 0)
  },
  methods: {
    fieldState (field) {
      if (this.v$.materialTemplate[field] == null || !this.v$.materialTemplate[field].$dirty) {
        return null
      }
      return !this.v$.materialTemplate[field].$error
    },
    addMaterial () {
      this.v$.$touch()
      if (this.v$.materialTemplate.$error) {
        console.log(' Error adding mat')
        return
      }
      this.calcdata.materials.push(this.materialTemplate)
      this.alertData.text = this.$t('entry.saved.label')
      this.resetMaterial()
    },
    editMaterial (uuid) {
      const material = _.find(this.calcdata.materials, o => o.uuid === uuid)
      this.edit = true
      this.materialTemplate.description = material.description
      this.materialTemplate.amount = material.amount
      this.materialTemplate.uuid = uuid
      return null
    },
    updateMaterial (uuid) {
      this.v$.$touch()
      if (this.v$.materialTemplate.$error) return
      const material = _.find(this.calcdata.materials, o => o.uuid === uuid)
      this.edit = false
      material.description = this.materialTemplate.description
      material.amount = this.materialTemplate.amount
      this.alertData = { text: this.$t('entry.saved.label'), variant: 'info' }
      this.resetMaterial()
    },
    removeMaterial (uuid) {
      this.$bvModal.msgBoxConfirm(this.$t('deleteConfirm.message'), this.confirmBoxOptions())
        .then(value => {
          if (value === true) {
            const idx = _.findIndex(this.calcdata.materials, o => o.uuid === uuid)
            this.calcdata.materials.splice(idx, 1)
            this.alertData = { text: this.$t('entry.deleted.label'), variant: 'info' }
          } else {
            this.alertData.text = null
          }
        })
    },
    cancelEdit () {
      this.resetMaterial()
      this.edit = false
      this.alertData.text = null
    },
    resetMaterial () {
      this.materialTemplate = this.freshMaterialTemplate()
      this.edit = false
      this.v$.$reset()
    },
    freshMaterialTemplate () {
      return {
        description: null,
        amount: null,
        uuid: this.uuidv4()
      }
    }
  }
}
</script>

<style scoped>

</style>
