<template>
  <div>
    <b-form-select value-field="id" :value="selected" @change="updateValue" :options="overheadsurcharges" ></b-form-select>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'OverheadSurcharge',
  props: {
    value: {
      // type: Object,
      required: false
    }
  },
  computed: {
    overheadsurcharges () {
      return this.$store.state.calc.overheadsurcharges
    }
  },
  data () {
    return {
      selected: null
    }
  },
  mounted () {
    if (this.value) {
      this.selected = this.value.id
    }
  },
  methods: {
    updateValue (selectedId) {
      const selectedOption = _.find(this.overheadsurcharges, (o) => o.id === selectedId)
      this.$emit('input', selectedOption)
    }
  }
}
</script>

<style scoped>

</style>
