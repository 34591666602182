<template>
  <b-form-datepicker
    @input="$emit('input', $event)"
    :id="id"
    :start-weekday="1"
    :hide-header="true"
    locale="de"
    :value="value"
    :min="min"
    labelPrevDecade="Vorheriges Jahrzehnt"
    labelPrevYear="Vorheriges Jahr"
    labelPrevMonth="Vorheriger Monat"
    labelCurrentMonth="Aktueller Monat"
    labelNextMonth="Nächster Monat"
    labelNextYear="Nächstes Jahr"
    labelNextDecade="Nächstes Jahrzehnt"
    labelToday="Heute"
    labelSelected="Ausgewähltes Datum"
    labelNoDateSelected="Kein Datum gewählt"
    labelCalendar="Kalender"
    labelNav="Kalendernavigation"
    labelHelp="Mit den Pfeiltasten durch den Kalender navigieren"
  ></b-form-datepicker>
</template>

<script>
export default {
  name: 'AkDatepicker',
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: new Date().toISOString().slice(0, 10)
    },
    min: {
      type: Date
    }
  }
}
</script>

<style scoped>

</style>
