<template>
  <div>
    <!--    preferredOu: {{ preferredOu }}<br />-->
    <!--    store: {{ $store.state }}-->
    <b-form-group :state="validateState()">
      <multiselect :clearOnSelect="false" :preserveSearch="true" :internal-search="false" :value="value"
                   @select="$emit('select', $event)" :options="options" track-by="id" label="name" :loading="isOuLoading"
                   @search-change="debouncedFindOu" :placeholder="$t('base.orgunit.placeholder')">
        <template v-slot:singleLabel="{ option }">
          <span class="text-secondary">{{ option.id }}</span> {{ option.name }}
        </template>
        <template v-slot:option="{ option }">
          <span class="text-secondary">{{ option.id }}</span> {{ option.name }}
        </template>
      </multiselect>
      <template v-slot:description>
        <slot name="description" :value="value">
          <template v-if="value">
            <template v-if="isOuPreferred">{{ $t('base.orgunit.preferred.text') }}</template>
            <b-form-checkbox v-else @change="setPreferredOu">{{ $t('base.orgunit.save.as.preferred.label') }}</b-form-checkbox>
          </template>
        </slot>
      </template>
      <b-form-invalid-feedback v-for="error of v$.$errors" :key="error.$uid" :state="validateState()">{{ error.$message }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import _ from 'lodash'
import CalcService from '@/services/calc.service'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  name: 'OrgUnitSelect',
  props: {
    value: {
      type: Object
    }
  },
  components: {
    Multiselect
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      value: { required: helpers.withMessage(() => this.$t('validation.required.text'), required) }
    }
  },
  data () {
    return {
      options: [],
      isOuLoading: false,
      debouncedFindOu: null
    }
  },
  created () {
    this.debouncedFindOu = _.debounce(this.findOu, 300)
    if (this.preferredOu) {
      this.options.push(this.preferredOu)
    }
  },
  computed: {
    useInvalidFeedback () {
      return this.state !== undefined
    },
    preferredOu () {
      return this.$store.state.calc.preferredOu
    },
    isOuPreferred () {
      const preferredOu = this.preferredOu
      // console.log(_.get(this.preferredOu, 'id'))
      if (preferredOu && preferredOu.id === _.get(this.value, 'id')) {
        return true
      }
      return false
    }
  },
  methods: {
    validateState () {
      const { $dirty, $error } = this.v$.value
      return $dirty ? !$error : null
    },
    setPreferredOu () {
      this.$store.dispatch('calc/setPreferredOu', this.value)
    },
    findOu (query) {
      if (!_.isEmpty(query)) {
        this.isOuLoading = true
        const params = { filter: query }
        return CalcService.findOu(params).then(
          response => {
            this.options = response
            if (this.preferredOu && _.findIndex(this.options, o => (o.id === this.preferredOu.id)) < 0) {
              this.options.splice(0, 0, this.preferredOu)
            }
          }
        ).catch(
          error => this.makeToast(this.$i18n.t('error.text', {
            id: 'Suche',
            status: error.status,
            message: error.message
          }), this.$i18n.t('result.error.title'), 'danger')
        ).finally(
          () => {
            this.isOuLoading = false
          }
        )
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>
