<template>
<div>
    <h2 v-if="calcdata.calculationNumber">{{ $t("headingGeneral") }} zu {{ calcdata.calculationNumber}}</h2>
    <h2 v-else>{{ $t("headingGeneral") }}</h2>
    <b-card class="mt-2" >
      <template #header>
        <h6 class="mb-0">{{  $t("organization.label") }}</h6>
      </template>
      <b-row class="my-1">
        <b-col sm="3">
          <label for="field-organization">{{ $t("organization.label") }}*</label>
        </b-col>
        <b-col sm="9">
          <OrgUnitSelect id="field-organization" :value="calcdata.organization"
                         @select="orgselected"></OrgUnitSelect>
        </b-col>
      </b-row>
      <b-row class="my-1" v-if="calcdata.organization">
        <b-col sm="3">
          <label for="field-overheadSurcharge">{{ $t("overheadSurcharge.label") }}*</label>
        </b-col>
        <b-col sm="9" v-if="!hasPredefinedOSC()">
          <OverheadSurcharge  id="field-overheadSurcharge"
                              :value="calcdata.overheadSurchargeFac"
                              @input="setOsc"/>
        </b-col>
        <b-col sm="9" v-else-if="ownCalcPossible() && isMaterialProject()">
          {{ calcdata.overheadSurcharge}}%
        </b-col>
        <b-col sm="9" v-else>
          {{ calcdata.overheadSurchargeFac? calcdata.overheadSurchargeFac.text : ''}}
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-2"  v-if="ownCalcPossible()">
      <template #header>
        <h6>{{  $t("calculationType.label") }}</h6>
      </template>
      <b-card-text><b-alert show variant="warning"><b-icon-info-circle/> <p v-html="$t('calculationTypes.info.text')"></p></b-alert></b-card-text>
      <b-form-group :label="$t('calculationTypes.choose.label')" v-slot="{ ariaDescribedby }">
        <b-form-radio-group id="calculationType-radios"  v-model="calcdata.calculationType" @change="calcTypeChanged">
          <b-form-radio :aria-describedby="ariaDescribedby" value="material">{{ $t("calculationType.material.label")}}</b-form-radio><br/>
          <b-form-radio :aria-describedby="ariaDescribedby"  value="personnel">{{ $t("calculationType.personnel.label")}}</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-card>

    <b-card class="mt-2">
      <template #header>
        <h6 class="mb-0">{{  $t("researchType.label") }}</h6>
      </template>
      <b-card-text><b-alert show><b-icon-info-circle/> <p v-html="$t('researchTypes.info.text')"></p></b-alert></b-card-text>
      <b-form-group :label="$t('researchTypes.choose.label')" v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="calcdata.researchType" :aria-describedby="ariaDescribedby" name="some-radios" value="contractresearch">{{  $t("researchType.contractresearch.label") }}</b-form-radio>
        <b-form-radio v-model="calcdata.researchType" :aria-describedby="ariaDescribedby" name="some-radios" value="certainknowledge">{{  $t("researchType.certainknowledge.label") }}</b-form-radio>
      </b-form-group>
    </b-card>

    <b-card class="mt-2 mb-4">
      <template #header>
        <h6 class="mb-0">{{  $t("projectInfo.label") }}</h6>
      </template>
      <template>
        <b-container>
          <b-row class="my-1" v-for="(value, name) in calcDefs" :key="name">
            <b-col sm="3">
              <label for="field-${name}">{{ $t(name+".label") }}{{ value.required?'*': ''}}</label>
            </b-col>
            <b-col sm="9">
              <AkDatepicker v-if="value.type === 'date'" class="mb-2" :id="`field-${name}`" :min="calcDefs[name].min" :value="calcdata[name]" @input="setDate(name, $event)" :state="hasErrors(name)"></AkDatepicker>
              <b-form-textarea v-else-if="value.type === 'textarea'" :id="`field-${name}`" v-model="calcdata[name]" rows="10"></b-form-textarea>
              <b-form-select v-else-if="value.type === 'countrySelect'" :id="`field-${name}`" v-model="calcdata[name]" :options="definitions.countries" value-field="iso" text-field="name" @change="clientCountryChanged"></b-form-select>
              <b-form-input v-else :id="`field-${name}`" :type="value.type" v-model="calcdata[name]" :state="hasErrors(name)" @blur="v$.calcdata[name].$touch"></b-form-input>
              <b-form-invalid-feedback :id="`field-${name}-input-live-feedback`" :state="hasErrors(name)">
                {{ $t(name+'.error') }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-button size="sm" variant="success" @click="doSave">{{ $t("button.check.label") }}</b-button>
        </b-container>
      </template>
    </b-card>

<!--  {{ v$.calcdata.title }}-->
<!--  <div :class="{ error: v$.calcdata.title.$errors.length }">-->
<!--    <div class="input-errors" v-for="(error, index) of v$.calcdata.title.$errors">-->
<!--      <div class="error-msg">{{ error.$message }}</div>-->
<!--    </div>-->
<!--  </div>-->

</div>
</template>

<script>
import UtilService from '@/services/util.service'
import AkDatepicker from '@/components/AkDatepicker'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import OrgUnitSelect from '@/components/calculation/OrgUnitSelect'
import OverheadSurcharge from '@/components/calculation/OverheadSurcharge'
import utilMixin from '@/mixins/util.mixin'
import _ from 'lodash'

export default {
  name: 'TheGeneral',
  components: { OrgUnitSelect, AkDatepicker, OverheadSurcharge },
  mixins: [utilMixin],
  props: {
    calcdata: {
      type: Object,
      required: true
    }
  },
  watch: {
    rt (newVal) {
      if (newVal === 'certainknowledge') {
        this.calcdata.researchBonus = 0
      }
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    definitions () {
      return this.$store.state.calc.definitions
    },
    rt () {
      return this.calcdata.researchType
    }
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    return {
      calcDefs: {
        unitLeader: { type: 'text', required: true },
        projectLeader: { type: 'text', required: true },
        title: { type: 'text', required: true },
        shortTitle: { type: 'text' },
        client: { type: 'text', required: true },
        clientCountry: { type: 'countrySelect', required: true },
        startDate: { type: 'date', required: true, min: today },
        endDate: { type: 'date', required: true, min: tomorrow },
        comment: { type: 'textarea' }
      }
    }
  },
  validations () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const startIsBefore = (value, vm) => new Date(value) > new Date(this.calcdata.startDate)
    const startIsInFuture = (value, vm) => new Date(value) >= today
    const endIsAfter = (value, vm) => new Date(value) < new Date(this.calcdata.endDate)
    return {
      calcdata: {
        unitLeader: { required },
        projectLeader: { required },
        organization: { },
        overheadSurcharge: { required },
        title: { required },
        shortTitle: { },
        client: { required },
        startDate: { required, endIsAfter, startIsInFuture },
        endDate: { required, startIsBefore }
      }
    }
  },
  mounted: function () {
    window.scrollTo(0, 0)
  },
  methods: {
    clientCountryChanged (newClientCountry) {
      const newCountry = _.find(this.definitions.countries, d =>
        d.iso === newClientCountry
      )
      this.calcdata.clientCountryName = newCountry.name
      this.calcdata.clientCountryEU = newCountry.euMember === true
      if (!this.calcdata.clientCountryEU) {
        const h = this.$createElement
        // eslint-disable-next-line no-unused-vars
        const messageVNode = h('div', { }, [
          h('p', { }, [
            ' Ihr Auftraggeber stammt nicht aus der Europäischen Union.  ',
            h('br'),
            'Bitte beachten Sie die ',
            h('a', {
              attrs: {
                href: 'https://fau.info/exportkontrolle',
                target: 'extern'
              }
            }, ' Regelungen der FAU zur Exportkontrolle'),
            ' und reichen Sie die „',
            h('a', {
              attrs: {
                href: 'https://www.doc.zuv.fau.de/IR/Exportkontrolle/Forschungsvertrag/Checkliste_Exportkontrolle_Forschungsvertrag.pdf'
              }
            }, 'Checkliste Exportkontrolle – Forschungsverträge'),
            '“ bei der Exportkontrolle ein.'
          ])
        ])
        this.$bvModal.msgBoxOk([messageVNode], { ...this.confirmBoxOptions(), size: 'lg', hideHeaderClose: true, okTitle: this.$t('calculation.nonEU.confirmed.text') })
        // this.$bvModal.msgBoxOk(this.$t('calculation.nonEU.confirm.text'), { ...this.confirmBoxOptions(), hideHeaderClose: true, okTitle: this.$t('calculation.nonEU.confirmed.text') })
          .then(value => {
            return true
          })
      }
    },
    calcTypeChanged (newCalcType) {
      if (newCalcType === 'personnel' && this.calcdata.machineCosts.length > 0) {
        this.$bvModal.msgBoxConfirm(this.$t('switchtopersonnel.confirm.text'), { ...this.confirmBoxOptions(), size: 'sm' })
          .then(value => {
            if (value) {
              this.setForCalcType(newCalcType)
            } else {
              this.calcdata.calculationType = 'material' // calceled, switch back
            }
          })
      } else {
        this.setForCalcType(newCalcType)
      }
    },
    setForCalcType (calcType) {
      console.log('setForCalcType')
      this.calcdata.calculationType = calcType
      if (this.calcdata.calculationType === 'material') {
        this.calcdata.overheadSurcharge = this.$store.state.calc.materialOverheadsurcharge
      } else {
        this.calcdata.machineCosts = []
        if (this.calcdata.overheadSurchargeFac != null) {
          this.calcdata.overheadSurcharge = this.calcdata.overheadSurchargeFac.value
        } else {
          this.calcdata.overheadSurcharge = null
        }
      }
    },
    isMaterialProject () {
      return this.calcdata.calculationType === 'material'
    },
    ownCalcPossible () {
      return this.calcdata.organization != null && _.includes(this.definitions.owncalcous, this.calcdata.organization.id)
    },
    hasErrors (field) {
      if (this.v$.calcdata[field] == null || !this.v$.calcdata[field].$dirty) {
        return null
      }
      return !this.v$.calcdata[field].$error
    },
    setDate (name, newValue) {
      this.calcdata[name] = newValue
      // EmpcostService.updateSalaries(this.calcdata.startDate, this.calcdata.endDate)
      UtilService.updateValuesForNewDate(this.calcdata.startDate, this.calcdata.endDate)
      this.calcdata.hkPrf = this.$store.state.calc.hkPrf
      this.calcdata.staffPrf = this.$store.state.calc.staffPrf
    },
    doSave () {
      this.v$.$touch()
    },
    getPredefinedOSC (ou) {
      return _.find(this.$store.state.calc.overheadsurcharges, (o) => ou && _.startsWith(ou.id, o.orgprefix))
    },
    hasPredefinedOSC () {
      return this.calcdata.organization && this.getPredefinedOSC(this.calcdata.organization) != null
    },
    isOwnCalcOu (ou) {
      return _.includes(this.definitions.owncalcous, ou.id)
    },
    setOsc (oscf) {
      this.calcdata.overheadSurchargeFac = oscf
      this.calcdata.overheadSurcharge = oscf.value
    },
    orgselected (ou) {
      this.calcdata.organization = ou
      const predefinedOSC = this.getPredefinedOSC(ou)
      if (this.isOwnCalcOu(ou)) {
        this.calcdata.overheadSurcharge = this.$store.state.calc.materialOverheadsurcharge
        this.calcdata.calculationType = 'material'
        this.calcdata.overheadSurchargeFac = predefinedOSC
      } else {
        this.calcdata.calculationType = 'personnel'
        this.calcdata.machineCosts = []
        this.localCalculationType = 'personnel'
        if (predefinedOSC) {
          this.calcdata.overheadSurchargeFac = predefinedOSC
          this.calcdata.overheadSurcharge = predefinedOSC.value
        }
      }
      if (!predefinedOSC) {
        this.calcdata.overheadSurchargeFac = null
        this.calcdata.overheadSurcharge = null
      }
    }
  }
}
</script>

<style >
  .b-calendar div.small {display: none;}
</style>
