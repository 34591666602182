<template>
  <div>
    <h2>{{ $t("headingProfitSurcharge") }}</h2>
    <b-card>
      <template #header>
        <h6>{{ $t('sums.label') }}</h6>
      </template>
      <table class="table table-sm">
        <tbody>
          <tr>
            <td>{{ $t('employeesCost.label') }}</td>
            <td class="text-right">{{ formatEuro(totalEmployeeResearchBonusCost()) }}</td>
          </tr>
        <tr>
          <td>{{ $t('material.label') }}</td>
          <td class="text-right">{{ formatEuro(totalMaterialCost()) }}</td>
        </tr>
        <tr class="font-weight-bold table-active">
          <td>{{ $t('fullCost.label') }}</td>
          <td class="text-right">{{ formatEuro(fullCost()) }}</td>
        </tr>
        <tr>
          <td>{{ $t('profitSurcharge.percent.label', {percent: formatNumber(calcdata.profitSurcharge)}) }}</td>
          <td class="text-right">{{ formatEuro(profitSurcharge())}}</td>
        </tr>
        <tr class="font-weight-bold table-active">
          <td>{{ $t('offerprice.label')}}</td>
          <td class="text-right">{{ formatEuro(offerprice())}}</td>
        </tr>
        </tbody>
      </table>
    </b-card>
    <b-card class="mt-2">
      <template #header>
        <h6>{{ $t('profitSurcharge.label') }}</h6>
      </template>
      <table class="table table-sm">
        <tr>
          <td><label for="profitSurchargeField">{{ $t("profitSurcharge.hint.label") }}</label></td>
          <td>
<!--            <b-form-input id="profitSurcharge" type="number" lang="de-DE" v-model="calcdata.profitSurcharge" step="0.1" min="2" :state="validatePSC()"/>-->
            <vue-numeric id="profitSurchargeField" separator="." v-bind:min="2" v-bind:precision="2" v-model.number="calcdata.profitSurcharge" ></vue-numeric>
            <b-form-invalid-feedback id="profitSurcharge-input-live-feedback" :state="validatePSC()">
              {{ $t('profitSurcharge.error') }}
            </b-form-invalid-feedback>
          </td>
        </tr>
      </table>
    </b-card>
</div>
</template>

<script>
import utilMixin from '@/mixins/util.mixin'
import VueNumeric from 'vue-numeric'

export default {
  name: 'TheProfitSurcharge',
  mixins: [utilMixin],
  components: {
    VueNumeric
  },
  props: {
    calcdata: {
      type: Object,
      required: true
    }
  },
  computed: {},
  methods: {
    validatePSC () {
      return this.calcdata.profitSurcharge != null && Number(this.calcdata.profitSurcharge) >= 2
    }
  },
  mounted: function () {
    window.scrollTo(0, 0)
    document.getElementById('profitSurchargeField').focus()
  }
}
</script>

<style scoped>

</style>
