<template>
  <div class="row" v-if="calcdata">
    <div class="col-12 col-md-3 col-xl-3 ak-navbar">
      <TheSidebar :calcdata="calcdata"/>
      <TheBaReSuDeButtons class="mt-3"
                          @submit="submit"
                          @adminlist="adminlist($event)"
                          @delete="$emit('delete', $event)"
                          @back="confirmLeave($event)"
                          @reset="reset"
                          @pdf="confirmPdf" @createNumber="$emit('createNumber', $event)"
                          :show-back="true"
                          :show-reset="false && hasChanged"
                          :show-submit="!ro"
                          :show-adminlist="isInternalAudit()"
                          :disable-submit="!canEdit || !hasChanged"
                          :show-delete="showDelete"
                          :show-pdf="canPdf"
                          :show-create-number="showCreateNumber"/>
<!--      <div class="mt-2 border">
        <div id="pot">(\/) (;,,;) (\/)</div>
        <p class="mt-3">debug (remove me) patches:</p>
        <ul>
          <li v-for="(patch, idx) in patches" :key="idx">{{idx}} {{ patch }}</li>
        </ul>
      </div>-->
    </div>
    <div class="col-12 col-md-9 col-xl-9">
      <b-card no-body >
<!--        <b-tabs v-if="canEdit || isCalcadvisor" v-model="tabIndex" content-class="mt-3 mx-1" nav-wrapper-class="sticky-top sticky-navtabs bg-light">-->
        <b-tabs v-if="canEdit && !ro" v-model="tabIndex" content-class="mt-3 mx-1" nav-wrapper-class="sticky-top sticky-navtabs bg-light">
          <b-tab v-if="isCalcadvisor && calcdata.calculationNumber" :title="$t('headingAost')" lazy><TheAost :calcdata="calcdata" /></b-tab>
          <b-tab :title="$t('headingGeneral')" lazy><TheGeneral :calcdata="calcdata" /></b-tab>
          <b-tab :title="$t('headingEmployees')" lazy><TheEmployee :calcdata="calcdata"/></b-tab>
          <b-tab :title="$t('headingMaterials')" lazy><TheMaterial :calcdata="calcdata"/></b-tab>
          <b-tab :title="$t('headingProfitSurcharge')" lazy><TheProfitSurcharge :calcdata="calcdata"/></b-tab>
          <b-tab :title="$t('headingSummary')" lazy><TheShow :calcdata="calcdata" :readOnlyView="false"/></b-tab>
<!--              <b-tab title="More coming soon..." disabled><p>I'm a disabled tab!</p></b-tab>-->
        </b-tabs>
          <div v-else>
            <TheShow :calcdata="calcdata" :readOnlyView="true" />
          </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import TheGeneral from '@/components/TheGeneral'
import TheEmployee from '@/components/TheEmployee'
import TheMaterial from '@/components/TheMaterial'
import TheProfitSurcharge from '@/components/TheProfitSurcharge'
import TheShow from '@/components/TheShow'
import _ from 'lodash'
import { authMixin } from '@/mixins/auth.mixin'
import utilMixin from '@/mixins/util.mixin'
import TheSidebar from '@/components/TheSidebar'
import TheBaReSuDeButtons from '@/components/TheBaReSuDeButtons'
import axios from 'axios'
import fileDownload from 'js-file-download'
import * as jsonpatch from 'fast-json-patch'
import TheAost from '@/components/TheAost'

export default {
  name: 'TheCalculation',
  components: { TheAost, TheBaReSuDeButtons, TheSidebar, TheGeneral, TheEmployee, TheMaterial, TheProfitSurcharge, TheShow },
  mixins: [authMixin, utilMixin],
  props: {
    calcdata: {
      type: Object,
      required: true
    },
    ro: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data () {
    return {
      tabIndex: 0,
      calcSaved: null
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: function () {
    this.calcSaved = _.cloneDeep(this.calcdata)
    console.log('CLONE?' + this.calcdata.isCloned + ', cloneOf: ' + this.calcdata.cloneOf)
    if (this.calcdata.isCloned) {
      delete this.calcSaved.isCloned
      delete this.calcdata.isCloned
      this.calcdata.title = this.calcdata.title + ' (Kopie)'
    }
  },
  beforeDestroy: function () {
    console.log('beforeDestroy, changes: ' + this.hasChanged)
  },
  computed: {
    hasChanged () {
      return this.patches.length > 0
    },
    patches () {
      if (this.calcdata && this.calcSaved) {
        const left = jsonpatch.compare(this.calcdata, this.calcSaved)
        const right = jsonpatch.compare(this.calcSaved, this.calcdata)
        const res = []
        left.forEach(function (l) {
          const r = _.find(right, { path: l.path })
          if (l.value !== r.value && !(l.value === '' && r.value === null) && !(r.value === '' && l.value === null) &&
          !l.path.startsWith('/_meta') && !l.path.startsWith('/acls')) {
            res.push({ path: l.path, old: l.value, new: r.value })
          }
        })
        return res
      }
      return []
    },
    pdfUrl () {
      return 'https://www.angebotskalkulation.zuv.fau.de/ak3/pdf/generate/' + this.calcdata.uuid
    },
    canEdit () {
      return (this.calcdata && !this.calcdata._meta) || (this.isOwner || this.isWrite)
    },
    canPdf () {
      return (this.isOwner || this.isWrite || this.isRead) && (this.calcdata !== null && this.calcdata._meta !== null)
    },
    isRead () {
      // console.log('this.currentUser: ' + this.currentUser.username)
      if (this.calcdata) {
        if (_.has(this.calcdata, 'acls')) {
          if (_.find(this.calcdata.acls, acl => acl.id === this.currentUser.username && acl.right === 'read')) {
            return true
          }
        }
      }
      return false
    },
    isOwner () {
      // console.log('this.currentUser: ' + this.currentUser.username)
      if (this.calcdata) {
        if (_.has(this.calcdata, 'acls')) {
          if (_.find(this.calcdata.acls, acl => acl.id === this.currentUser.username && acl.right === 'owner')) {
            return true
          }
        }
      }
      return false
    },
    isWrite () {
      if (this.calcdata) {
        if (_.has(this.calcdata, 'acls')) {
          if (_.find(this.calcdata.acls, acl => acl.id === this.currentUser.username && acl.right === 'write')) {
            return true
          }
        }
      }
      return false
    },
    showCreateNumber () {
      return this.canEdit && !this.calcdata.calculationNumber
    },
    showDelete () {
      return this.isOwner && !this.calcdata.calculationNumber
    }
  },
  methods: {
    adminlist () {
      if (this.hasChanged) {
        this.$bvModal.msgBoxConfirm(this.$t('leaveunsaved.confirm.text'), { ...this.confirmBoxOptions(), size: 'sm' })
          .then(value => {
            if (value) {
              this.$router.push('/adminlist')
            }
          })
      } else {
        this.$router.push('/adminlist')
      }
    },
    submit () {
      this.$emit('submit', this.calcdata)
      this.calcSaved = _.cloneDeep(this.calcdata)
    },
    reset () {
      this.$bvModal.msgBoxConfirm(this.$t('reset.confirm.text'), { ...this.confirmBoxOptions(), size: 'sm' })
        .then(value => {
          if (value) {
            this.$emit('reset', _.cloneDeep(this.calcSaved))
          }
        })
    },
    confirmLeave (event) {
      if (this.hasChanged) {
        this.$bvModal.msgBoxConfirm(this.$t('leaveunsaved.confirm.text'), { ...this.confirmBoxOptions(), size: 'sm' })
          .then(value => {
            if (value) {
              this.$router.push('/list')
            }
          })
      } else {
        this.$router.push('/list')
      }
    },
    confirmPdf () {
      if (this.hasChanged) {
        this.$bvModal.msgBoxConfirm(this.$t('pdf.unsaved.confirm.text'), this.confirmBoxOptions())
          .then(value => {
            if (value) {
              this.close()
            }
          })
      } else {
        this.generatePdf()
      }
    },
    generatePdf () {
      axios({
        url: this.pdfUrl,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileName = response.headers.pdffilename
        fileDownload(response.data, fileName)
      })
    }
  }
}
</script>

<style>
.sticky-navtabs {
  position: sticky;
  top: 56px;
}
#pot {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 5s;
}
@-webkit-keyframes run {
  0% {
    left: 7%;
  }
  50% {
    left: 70%;
  }
  100% {
    left: 7%;
  }
}
</style>
